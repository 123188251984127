import React, { useEffect, useRef, useState, useCallback } from 'react';
import Draggable from 'react-draggable';
import * as atlas from 'azure-maps-control';
import { useNavigate } from 'react-router-dom';
import { auth } from '../firebase';
import { signOut } from 'firebase/auth';
import { useUser } from './UserContext';
import { Box, AppBar, Toolbar, InputBase, IconButton, Button, List, ListItem, Typography, Badge,  ThemeProvider, createTheme} from '@mui/material';
import datacentersData from '../data/datacenters.json';
import * as Cesium from 'cesium';
import 'cesium/Build/Cesium/Widgets/widgets.css';

import LoadingOverlay from '@speedy4all/react-loading-overlay';
import DatacenterDeployments from './DatacenterDeployments';
import UserProfilePanel from './UserProfilePanel'; 
import MapStyleController from './MapStyleController.jsx';
import ChatbotComponent from './ChatbotComponent';
import FacilityComponent  from './FacilityComponent.jsx';
import LanduseTimeline from './LanduseTimeline.jsx';
import OSMLanduseMap from './OSMLanduseMap.jsx';
import OSMLanduseData from './OSMLanduseData';
import DeploymentsWindow from './DeploymentsWindow.jsx';
import GlobalKPIWindow from './GlobalKPIWindow.jsx';
import AzureMapDirect from './AzureMapDirect';
import MapShippingRoutes from './MapShippingRoutes.jsx';

import NotificationIcon from '@mui/icons-material/Notifications';
import SearchIcon from '@mui/icons-material/Search';
import FactoryIcon from '@mui/icons-material/Factory';
import ShippingIcon from '@mui/icons-material/LocalShipping';
import BarChartIcon from '@mui/icons-material/BarChart';
import ChatIcon from '@mui/icons-material/Chat';
import CloseIcon from '@mui/icons-material/Close';
import NotificationsComponent from './NotificationsComponent.jsx';
import ShipmentsTracker from './ShipmentsTracker.jsx';
import MapShipmentsRoutes from './MapShipmentsRoutes.jsx';
import MapPointMarker from './MapPointMarker.jsx';
import MapPathMaker from './MapPathMaker.jsx';
import DCInfoWindow from './DCInfoWindow.jsx';

const theme = createTheme({
  palette: {
      mode: 'dark',
      primary: {
        main: '#BFECFF',
      },
      secondary: {
        main: '#1976d2',
      },
    },
    shape: {
      borderRadius: 20,
    },
    spacing: 10,
  });

const facilityData = {
  FacilityName: "Quincy Datacenter",
  FacilityCoordinates: { lat: -119.85222, long: 47.23361 },
  FacilityAddress: {
    street: "123 Datacenter Ave",
    city: "Quincy",
    state: "WA",
    postalCode: "98848",
    country: "USA",
    region: "US West"
  },
  FacilityType: "Datacenter",
  FacilityLocation: "US West",
  FacilityStatus: "Operational",
  FacilityCoolingType: "Indirect Evaporative Cooling"
};

const FacilitiesButton = ({ onClick, isSelected }) => {
  return (
    <Button
      variant="contained"
      onClick={onClick}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: isSelected ? '#2F2D32' : '#1D1B20',
        color: '#BFECFF',
        borderRadius: '10px',
        padding: '3px', 
        marginLeft: '3px', 
        minWidth: '70px',  
        '&:hover': {
          backgroundColor: '#2F2D32',
        },
        '&:active': {
          backgroundColor: '#2F2D32',
        },
      }}>
      <FactoryIcon sx={{ width: 32, height: 32 }} />
      <Typography 
        variant="caption" 
        sx={{ 
          color: '#BFECFF', 
          mt: 0.5, 
          fontSize: '10px',
          textAlign: 'center'
        }}
      >
        Facilities
      </Typography>
    </Button>
  );
}

const ShippingButton = ({ onClick, isSelected }) => {
  return (
    <Button
      variant="contained"
      onClick={onClick}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: isSelected ? '#2F2D32' : '#1D1B20',
        color: '#BFECFF',
        borderRadius: '10px',
        padding: '3px',  
        marginLeft: '3px',
        minWidth: '70px',  
        '&:hover': {
          backgroundColor: '#2F2D32',
        },
        '&:active': {
          backgroundColor: '#2F2D32',
        },
      }}
    >
      <ShippingIcon sx={{ width: 32, height: 32 }} />
      <Typography 
        variant="caption" 
        sx={{ 
          color: '#BFECFF', 
          mt: 0.5, 
          fontSize: '10px',
          textAlign: 'center'
        }}
      >
        Deployments
      </Typography>
    </Button>
  );
}

const KPIButton = ({ onClick, isSelected }) => {
  return (
    <Button
      variant="contained"
      onClick={onClick}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: isSelected ? '#2F2D32' : '#1D1B20',
        color: '#BFECFF',
        borderRadius: '10px',
        padding: '3px', 
        marginLeft: '3px', 
        minWidth: '70px',  
        '&:hover': {
          backgroundColor: '#2F2D32',
        },
        '&:active': {
          backgroundColor: '#2F2D32',
        },
      }}
    >
      <BarChartIcon sx={{ width: 32, height: 32 }} />
      <Typography 
        variant="caption" 
        sx={{ 
          color: '#BFECFF', 
          mt: 0.5, 
          fontSize: '10px',
          textAlign: 'center'
        }}
      >
        Metrics
      </Typography>
    </Button>
  );
}

const ChatButton = ({ onClick, isSelected }) => {
  return (
    <Button
      variant="contained"
      onClick={onClick}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: isSelected ? '#2F2D32' : '#1D1B20',
        color: '#BFECFF',
        borderRadius: '10px',
        padding: '3px',
        marginLeft: '3px',  
        minWidth: '70px',  
        '&:hover': {
          backgroundColor: '#2F2D32',
        },
        '&:active': {
          backgroundColor: '#2F2D32',
        },
      }}
    >
      <ChatIcon sx={{ width: 32, height: 32 }} />
      <Typography 
        variant="caption" 
        sx={{ 
          color: '#BFECFF', 
          mt: 0.5, 
          fontSize: '10px',
          textAlign: 'center'
        }}
      >
        Copilot
      </Typography>
    </Button>
  );
}

export const LayoutMain = () => {
  const navigate = useNavigate();
  const { userPreferences } = useUser();
  const [isLoading, setIsLoading] = useState(true);
  const [mapStyle] = useState('night');
  const [map, setMap] = useState(null);
  const mapRef = useRef(null);
  const [dcData, setDCData] = useState(null);
  const [spData, setSPData] = useState(null);
  const [apData, setAPData] = useState(null);
  const initializationDoneRef = useRef(false);
  const [selectedDC, setSelectedDC] = useState(null);
  const [shipmentsRoutesReset, setShipmentsRoutesReset] = useState(false);
  const [routesMapLayers, setRoutesMapLayers] = useState(null);

  const handleLayersCreated = useCallback((layers) => {
    setRoutesMapLayers(layers);
  }, []);

  const resetShipmentRoutes = useCallback(() => {
    if (routesMapLayers && map) {
      [...routesMapLayers.originalLayers, ...routesMapLayers.originalMarkers, ...routesMapLayers.alternativeLayers, ...routesMapLayers.alternativeMarkers, ...routesMapLayers.pinMarkers].forEach(obj => {
            if (obj.setOptions) {
              obj.setOptions({ visible: false });
            }
        });
    }
    setShipmentsRoutesReset(false);
}, [routesMapLayers, map]);

const handleLayersVisibility = (altRoutesFlag, originalRoutesFlag) => {
  if (routesMapLayers && map) {
    routesMapLayers.originalLayers.forEach(layer => {
      if (layer.getOptions) {
        layer.setOptions({ visible: originalRoutesFlag });
      } 
    });
    routesMapLayers.originalMarkers.forEach(marker => {
      if (marker.getOptions) {
          marker.setOptions({ visible: originalRoutesFlag });
      } 
    });
    routesMapLayers.alternativeLayers.forEach(layer => {
      if (layer.getOptions) {
        layer.setOptions({ visible: altRoutesFlag });
      } 
    });
    routesMapLayers.alternativeMarkers.forEach(marker => {
      if (marker.getOptions) {
          marker.setOptions({ visible: altRoutesFlag });
      } 
    });
  
  }
};

useEffect(() => {
    if (shipmentsRoutesReset) {
        resetShipmentRoutes();
    }
}, [shipmentsRoutesReset, resetShipmentRoutes]);

  const handleDCSelect = (dcData) => {
    setSelectedDC(dcData);
  };

  const handleDCClose = () => {
    setSelectedDC(null);
  };

  const initializeMap = useCallback(async () => {
    if (map) {
      map.dispose();
    }
    
    const newMap = new atlas.Map('myMap', {
      center: [240, 0],
      zoom: 2,
      view: 'Auto',
      style: mapStyle || 'night',
      language: 'en-US',
      showLogo: false,
      showFeedbackLink: false,
      authOptions: {
        authType: 'subscriptionKey',
        subscriptionKey: process.env.REACT_APP_AZURE_API_KEY
      },
      dragRotateInteraction: false,
    });
    
    newMap.events.add('ready', async () => {
      setMap(newMap);
  
      try {
        const datacenters = await import('../data/datacenters.json');
        setDCData(datacenters.default);
        
        const ports = await import('../data/ports.json');
        setSPData(ports.default.elements);
        
        const airports = await import('../data/airports.json');
        setAPData(airports.default.elements);
        
      } catch (error) {
        console.error('Error loading data:', error);
      } finally {
        setIsLoading(false);
        initializationDoneRef.current = true;
      }
    });
  }, [map, mapStyle, setMap, setDCData, setSPData, setAPData, setIsLoading]);

  const initializeMapRef = useRef();
  
  useEffect(() => {
    initializeMapRef.current = initializeMap;
  }, [initializeMap]);
  
  useEffect(() => {
    if (mapRef.current) {
      initializeMapRef.current();
    }
  }, []);

  
  
  const [showShippingRoutes, setShowShippingRoutes] = useState(false);
  const [fbActive, setFBActive] = useState(false);
  const [hbActive, setHBActive] = useState(false);
  const [cbActive, setCBActive] = useState(false);
  const [kbActive, setKBActive] = useState(false);
  const [nActive, setNActive] = useState(false);
  const [notifBageVisible, setNotifBageVisible] = useState(true);
  const [activeMapStyle, setActiveMapStyle] = useState('night');
  const [isFacilityFinderOpen, setIsFacilityFinderOpen] = useState(false);
  const [showLanduseMap, setShowLanduseMap] = useState(false);
  const [showShipmentsTracker, setShowShipmentsTracker] = useState(false);
  const [isShippingVisible, setIsShippingVisible] = useState(false);
  const [showKPIsWindow, setShowKPIsWindow] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 74 });
  const [KPIPosition, setKPIPosition] = useState({ x: 0, y: 74 });
  const [shippingWindowHeight, setShippingWindowHeight] = useState(390);
  const [selectedFacilityCoordinates, setSelectedFacilityCoordinates] = useState(null);
  const [searchInput, setSearchInput] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [showFacilityInfo, setShowFacilityInfo] = useState(false);
  const [showFacilityFinder, setShowFacilityFinder] = useState(true);
  const [mode, setMode] = useState('default');
  const [currentFacilityData, setCurrentFacilityData] = useState(facilityData);
  const [showDeploymentsTable, setShowDeploymentsTable] = useState(false);
  const [deploymentsActiveTab, setDeploymentsActiveTab] = useState(0);
  const [selectedYear, setSelectedYear] = useState(2024);
  
  const [shippingAltRoutes, setShippingAltRoutes] = useState(false);
  const [showShippingAltRoutes, setShowShippingAltRoutes] = useState(false);
  const [shippingOriginalRoutes, setShippingOriginalRoutes] = useState(true);
  const [zoomTo, setZoomTo] = useState(null);
  const [zoomScale, setZoomScale] = useState(null);
  const [shippingLayers, setShippingLayers] = useState([]);
  const [overlays, setOverlays] = useState({
    current: true,
    gapFilled2020: false
  });
  const [showCesiumMap, setShowCesiumMap] = useState(false);

  const [isViewerInitialized, setIsViewerInitialized] = useState(false);
  const cesiumContainerRef = useRef(null);
  const viewerRef = useRef(null);
  const [isLoadingCesium, setIsLoadingCesium] = useState(false);
  
  const initializeCesiumViewer = async () => {
    if (cesiumContainerRef.current && !isViewerInitialized) {
      setIsLoadingCesium(true);
    
      Cesium.Ion.defaultAccessToken =  process.env.REACT_APP_CESIUMION_ACCESS_TOKEN

      window.CESIUM_BASE_URL = '/cesium/';

      viewerRef.current = new Cesium.Viewer(cesiumContainerRef.current, {
        globe: false,
        baseLayerPicker: false,
        animation: false,
        timeline: false,
        fullscreenButton: false,
        homeButton: false,
        infoBox: false,
        sceneModePicker: false,
        navigationHelpButton: false,
        geocoder: false,
        enableLighting: true,
        requestRenderMode: true,
        maximumRenderTimeChange: Infinity,
        skyBox: Cesium.SkyBox.createEarthSkyBox(),
        skyAtmosphere: new Cesium.SkyAtmosphere(),
      });

      const createTerrain = async () => {
        try {
          const terrainProvider = await Cesium.createWorldTerrainAsync();
          
          return terrainProvider;
        } catch (error) {
            console.error('Error creating terrain provider:', error);
          return null;
        }
      };

    
      const initializeCesium = async () => {
        try {
          const terrainProvider = await createTerrain();
          if (terrainProvider) {
            viewerRef.current.terrainProvider = terrainProvider;
          }
            
          const tilesetProvider = await Cesium.Cesium3DTileset.fromIonAssetId(2275207, 
            { 
              enableCollision: true,
              maximumScreenSpaceError: 8 
             });
          viewerRef.current.scene.primitives.add(tilesetProvider);
          viewerRef.current.camera.setView({
            destination: Cesium.Cartesian3.fromDegrees(-122.2035, 47.6104, 2000)
          });

          tilesetProvider.preloadWhenHidden = true;
          tilesetProvider.preloadFlightDestinations = true;
          
        } catch (error) {
          console.error(`Error creating tileset: ${error}`);
        }
      };

      const intializeDatacentersPins = async () => {
        try {
          const pinBuilder = new Cesium.PinBuilder();
          const pinImage = pinBuilder.fromText('Facility', Cesium.Color.RED, 48);
          var dataSourcePromise = viewerRef.current.dataSources.add(Cesium.GeoJsonDataSource.load(datacentersData, {
              billboard: {
                  image: pinImage,
                  verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
                  heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
                  disableDepthTestDistance: Number.POSITIVE_INFINITY,
                  scale: 1.5
              },
              clampToGround: true
          }));

          dataSourcePromise.then(function (cesiumDataSource) {
              var entities = cesiumDataSource.entities.values;
              for (var i = 0; i < entities.length; i++) {
                  var entity = entities[i];
                  entity.billboard.heightReference = Cesium.HeightReference.CLAMP_TO_GROUND;
              }
          });

        } catch (error) {
          console.error('error loading datacenters pin into cesium map');
        }
      }

      await initializeCesium();
      await intializeDatacentersPins();

      await new Promise(resolve => setTimeout(resolve, 5000));
      
      setIsViewerInitialized(true);
      setIsLoadingCesium(false);
    }
  };

  useEffect( () => {
    
    const init = async () => {
      if (document.readyState === 'complete') {
        await initializeCesiumViewer();
      } else {
        window.addEventListener('load', async () => {
          await initializeCesiumViewer();
        });
      }
    };

    init();

    return () => {
      if (viewerRef.current) {
        viewerRef.current.destroy();
      }
    };
  }, []);

  const preloadSites = async () => {
    if (!userPreferences) {
      return;
    }
  
    const coordinatesList = getPreloadDatacenters(userPreferences);
    
    if (coordinatesList.length === 0) {
      return;
    }
  
    for (const coordinates of coordinatesList) {
      preLoad(coordinates);
      await new Promise(resolve => setTimeout(resolve, 1000));
    }
  }

  useEffect(() => {
    if (viewerRef && userPreferences) {
      preloadSites();
    }
  }, [viewerRef, userPreferences]);
  
  
  const handleOverlayChange = useCallback((newOverlays) => {
    setOverlays(prev => ({ ...prev, ...newOverlays }));
  }, []);
  
  useEffect(() => {
    if ((!showCesiumMap || !showLanduseMap) && map) {
      map.resize();
    }
  }, [showLanduseMap, showCesiumMap , map]);

  
  const onShipmentsTrackerClose = () => {
    setShipmentsRoutesReset(true);
    setShowShipmentsTracker(false);
    enablePanning();
  }
  
  useEffect(() => {
    if (shipmentsRoutesReset) {
      const timer = setTimeout(() => {
        setShipmentsRoutesReset(false);
      }, 100);
      return () => clearTimeout(timer);
    }
  }, [shipmentsRoutesReset]);

  const handleShippingHeightChange = (newHeight) => {
    setShippingWindowHeight(newHeight);
  };

  const [filteredDatacenters] = useState([datacentersData.features || []]);
 
  const handleDrag = (e, ui) => {
    const { x, y } = ui;
    setPosition({ x, y });
  };

  const handleKPIDrag = (e, ui) => {
    const { x, y } = ui;
    setKPIPosition({ x, y });
  };

  const [activeTransits, setActiveTransits] = useState({
    datacenters: true,
    seaports: false,
    airports: false,
    ground: false,
    hubs: false
  });
  const [defaultFacilityMapStyle, setDefaultFacilityMapStyle] = useState('satellite');
  const [defaultSettingsMapStyle, setDefaultSettingsMapStyle] = useState('night');
  const [isChatbotOpen, setIsChatbotOpen] = useState(false);

  const toggleChatbot = () => {
    setShipmentsRoutesReset(true);
    setShowShipmentsTracker(false);
    setIsChatbotOpen(prevState => !prevState);
    toggleCB();
    if (nActive) toggleNotifications();
  };

  const [isFirstNotifOpen, setIsFirstNotifOpen] = useState(true);

  const toggleNotifications = () => {
    setNActive(prevState => !prevState);
    setNotifBageVisible(false);
    if (isChatbotOpen) toggleChatbot();
    if (nActive) setIsFirstNotifOpen(false);
  };

  const focusOnTheDeploymentAlert = () => {
    if (showShipmentsTracker) {
      onShipmentsTrackerClose();
    }
    
    handleUpcomingDeploymentsFacility({
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -119.8828,
          47.23342
        ]
      },
      "properties": {
        "FACILITY": "Quincy MWH06",
        "FACILITYSHORTCODE": "MWH06",
        "DCOPSREGION": "US West",
        "CITY": "Quincy",
        "OPERATIONALTAXONOMYID": "2",
        "IAMStaffGroupName": "MWH IAM Staff Group A",
        "OPERATIONALTAXONOMY": "Class A",
        "FACILITYPHASEID": "6",
        "FACILITYPHASE": "Operational",
        "HOURSOFOPERATION": "",
        "BILLINGTYPE": "Billable",
        "PLANNINGGROUPID": "MWH01",
        "COUNTRY": "United States",
        "TERRITORY": "North America",
        "REGION": "AMER",
        "REGIONGROUP": "DOMESTIC",
        "REGIONALDATACENTERMANAGER": "nasath",
        "LOGISTICSENGINEER": "nikonen;rimather",
        "LOGISTICSRECEIVINGTEAMEMAIL": "mwh06log",
        "FACILITYTYPEID": "31",
        "FACILITYTYPE": "Datacenter",
        "DCOPERATEDBY": "Mcio",
        "SPARESFACILITY": "514",
        "LOGISTICSCONSTRAINTS": "RequiresPackaging",
        "LOGISTICSRECEIVINGTEAMPHONE": "1 5097976797",
        "REGIONALINVENTORYANDASSETPROGRAMMANAGEREMAIL": "",
        "HOLIDAYS": "[{\"Item1\":\"2022-11-24\",\"Item2\":\"Thanksgiving Day\"},{\"Item1\":\"2022-11-25\",\"Item2\":\"Day after Thanksgiving\"},{\"Item1\":\"2022-12-23\",\"Item2\":\"Christmas Eve (MSFT observed)\"},{\"Item1\":\"2022-12-24\",\"Item2\":\"Christmas Eve\"},{\"Item1\":\"2022-12-25\",\"Item2\":\"Christmas Day\"},{\"Item1\":\"2022-12-26\",\"Item2\":\"Christmas Day (MSFT Observed)\"},{\"Item1\":\"2023-01-01\",\"Item2\":\"New Year's Day\"},{\"Item1\":\"2023-01-02\",\"Item2\":\"New Year's Day (MSFT Observed)\"},{\"Item1\":\"2023-01-16\",\"Item2\":\"MLK Day\"},{\"Item1\":\"2023-02-20\",\"Item2\":\"President's Day\"},{\"Item1\":\"2023-05-29\",\"Item2\":\"Memorial Day\"},{\"Item1\":\"2023-07-04\",\"Item2\":\"Independence Day\"},{\"Item1\":\"2023-09-04\",\"Item2\":\"Labor Day\"},{\"Item1\":\"2023-11-23\",\"Item2\":\"Thanksgiving Day\"},{\"Item1\":\"2023-11-24\",\"Item2\":\"Day after Thanksgiving\"},{\"Item1\":\"2023-12-25\",\"Item2\":\"Christmas Day\"},{\"Item1\":\"2023-12-26\",\"Item2\":\"Day After Christmas\"}]",
        "SHIPPINGSTATE": "WA",
        "SHIPPINGCITY": "Quincy",
        "SHIPPINGADDRESS": "1515 Port Industrial Way",
        "SHIPPINGZIPCODE": "98848-5002",
        "COMPANYCODE": "1010",
        "FACILITYOWNER": "Microsoft",
        "OMCSITESERVICESSUPPORTTYPE": "NotSupported",
        "COOLING": "Indirect Evaporative Cooling (Cooling Tower)",
        "FACILITYADDRESS": "1515 Port Industrial Way; c/o Microsoft; 98848-5002; Quincy; Washington; United States",
        "STREETADDRESS1": "1515 Port Industrial Way",
        "STREETADDRESS2": "c/o Microsoft",
        "WARRANTYLEARNERID": "PL72264946",
        "WARRANTYSHIPTO": "600962073",
        "WARRANTYTIMEZONE": "TZ14",
        "WARRANTYSOLDTO": "130134420",
        "SW_REGION_DETAILS": "US",
        "ISSPARED": "TRUE",
        "RWN": "1",
        "SparesPlantCode": "8129",
        "CurrentSparesSupportModel": "Supplier Warranted",
        "FutureSparesSupportModel": "Direct Warranted",
        "SparesTransitionDate": "6/1/2023",
        "EntityType": "maybeLate",
        "FACILITYGROUPNAME": "Quincy",
        "FACILITYGROUPSIZE": 9
      }});
    setDeploymentsActiveTab(1);
    showShipmentsRoutes();
    showShippingAndDeliveries();
    toggleNotifications();
  }

  const toggleFB = () => {
    setFBActive(!fbActive);
    return fbActive;
  }

  const toggleHB = () => {
    setHBActive(!hbActive);
    return hbActive;
  }

  const toggleCB = () => {
    setCBActive(!cbActive);
    return cbActive;
  }

  const toggleKB = () => {
    setKBActive(!kbActive);
    return kbActive;
  }

  const updateActiveTransits = (newTransits) => {
    setActiveTransits(prevTransits => ({
      ...prevTransits,
      ...newTransits
    }));
  };

  const handleMapStyleChange = useCallback((style) => {
    setActiveMapStyle(style);
    
    if (style === 'cesium') {
      setShowCesiumMap(true);
    } else {
      setShowCesiumMap(false);
    }
  }, [setActiveMapStyle, setShowCesiumMap]);

  useEffect(() => {
    console.log('showCesiumMap changed:', showCesiumMap);
  }, [showCesiumMap]);

  const getCurrentZoom = useCallback(() => {
    if (map) {
      return Math.round(map.getCamera().zoom);
    }
    return 2;
  }, [map]);

  const handleZoomIn = useCallback(() => {
    if (map) {
      const currentZoom = getCurrentZoom();
      const newZoom = Math.min(currentZoom + 1, 25);
      map.setCamera({ zoom: newZoom });
      setZoomScale(newZoom);
    }
  }, [map, getCurrentZoom]);
  
  const handleZoomOut = useCallback(() => {
    if (map) {
      const currentZoom = getCurrentZoom();
      const newZoom = Math.max(currentZoom - 1, 1);
      map.setCamera({ zoom: newZoom });
      setZoomScale(newZoom);
    }
  }, [map, getCurrentZoom]);

  const handleTransitToggle = useCallback((transitType) => {
    
  }, []);

  const getPreloadDatacenters = (userPreferences) => {
    const preloadCoordinates = [];
  
    if (userPreferences?.facilitiesSettings?.datacenterPreLoad) {
      userPreferences.facilitiesSettings.datacenterPreLoad.forEach(datacenter => {
        preloadCoordinates.push(datacenter.value);
      });
    }
  
    return preloadCoordinates;
  };
  

  useEffect(() => {

    const delay = 2000;

    const timer = setTimeout(() => {
      setIsLoading(false);
      if (userPreferences) {
        setDefaultFacilityMapStyle(userPreferences?.facilitiesSettings?.defaultView !== undefined ? userPreferences.facilitiesSettings.defaultView: 'satellite');
        setDefaultSettingsMapStyle(userPreferences?.defaultSettings?.mapDefaultStyle !== undefined ? userPreferences.defaultSettings.mapDefaultStyle: 'night');
        
        updateActiveTransits({
          datacenters: true,
          seaports: userPreferences.defaultSettings.showSeaports,
          airports: userPreferences.defaultSettings.showAirports
        });

        setActiveMapStyle(defaultSettingsMapStyle);
      }
      
    }, delay);

      return () => clearTimeout(timer); 
  }, [userPreferences, defaultFacilityMapStyle, defaultSettingsMapStyle]); 


  const handleYearSelect = (year) => {
    setSelectedYear(year);
  };

  const closeFacilityComponent = () => {
    if (!showFacilityInfo) {
      setIsFacilityFinderOpen(false);
    }
    setShowFacilityFinder(false);
    setFBActive(false);
  }

  const handleFacilityInfoClose = () => {
    setShowDeploymentsTable(false);
    setShowFacilityInfo(false);
    
    if (showLanduseMap) {
      setShowLanduseMap(false);
      zoomToCurrentFacility()
    }

    if (!showFacilityFinder) {
      setIsFacilityFinderOpen(false);
      setFBActive(false);
    } 
  }

  const handleCloseLandImpact = () => {
    setShowLanduseMap(false);
    setShowShippingRoutes(false);
    setShowDeploymentsTable(true);
    zoomToCurrentFacility();
  }

  const handleDatacenterDeploymentsClose = () => {
    setShowDeploymentsTable(false);

    zoomToCurrentFacility();
  }

  const resetTransitStates = () => {
    updateActiveTransits({
      datacenters: true,
      seaports: userPreferences.defaultSettings.showSeaports,
      airports: userPreferences.defaultSettings.showAirports
    });
  }

  const showShipmentsRoutes = () => {
    if (map) {
      map.layers.getLayers().forEach(layer => {
        layer.setOptions({
          visible: false
        });
      });
    }
    handleMapStyleChange('night');
    setZoomScale(2);
    setZoomTo([180,15]);
    setShowShippingRoutes(true);
    
  }

  const hideMapLayers = () => {
    if (map) {
      map.layers.getLayers().forEach(layer => {
        layer.setOptions({
          visible: false
        });
      });
    }
  }

  const hideShipmentsRoutes = () => {
      setShowShippingRoutes(false);
      if (map && shippingLayers.length > 0) {
          shippingLayers.forEach(item => {
            if (item instanceof atlas.layer.Layer) {
                item.setOptions({ visible: false });
            } else if (item instanceof atlas.HtmlMarker) {
                item.setOptions({ visible: false });
            }
          });
      }
  };

  const hideShipmentsRoutesAndShowFacility = () => {
    hideShipmentsRoutes();

    zoomToCurrentFacility();
  }

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchInput(value);

    if (value) {
      const filteredSuggestions = datacentersData.features.filter(facility =>
        facility.properties.FACILITY.toLowerCase().includes(value.toLowerCase())
      );
      setSuggestions(filteredSuggestions);
    } else {
      setSuggestions([]);
    }
  };

  const handleSuggestionClick = (facility) => {
    setMode('datacenters');
    setShowFacilityInfo(true);
    setIsFacilityFinderOpen(true);
    handleFacilitySelect(facility);
    setSearchInput('');
    setSuggestions([]);
    setIsShippingVisible(false);
    setHBActive(false);
    setKBActive(false);
  };

  const showShippingAndDeliveries = () => {
    setShowDeploymentsTable(true);
    if (showLanduseMap) {
      setShowLanduseMap(false);
      setShowShippingRoutes(false);
      zoomToCurrentFacility();
    }
  }

  const handleFacilitySelect = (facility) => {
    setActiveMapStyle(defaultFacilityMapStyle);
    
    setSelectedFacilityCoordinates(facility.geometry.coordinates);
    setZoomScale(17);
    setZoomTo(facility.geometry.coordinates);

    const newFacilityData = {
      FacilityName: facility.properties.FACILITY,
      FacilityCoordinates: { 
        long: facility.geometry.coordinates[0],
        lat: facility.geometry.coordinates[1]
      },
      FacilityAddress: {
        street: facility.properties.STREETADDRESS1 || "",
        street2: facility.properties.STREETADDRESS2 || "",
        city: facility.properties.CITY || "",
        state: facility.properties.SHIPPINGSTATE || "",
        postalCode: facility.properties.SHIPPINGZIPCODE || "",
        country: facility.properties.COUNTRY || "",
        region: facility.properties.REGION || ""
      },
      FacilityType: facility.properties.FACILITYTYPE || "",
      FacilityLocation: `${facility.properties.CITY || ""}, ${facility.properties.COUNTRY || ""}`,
      FacilityStatus: facility.properties.FACILITYPHASE || "",
      FacilityCoolingType: facility.properties.COOLING || ""
    };
  
    setCurrentFacilityData(newFacilityData);

    const coordinates = [facility.geometry.coordinates[0], facility.geometry.coordinates[1]];
    flyTo(coordinates);

    setShowFacilityInfo(true);
  };

  const flyTo = (coordinates, floor = 0, height = 800) => {
    if (viewerRef.current && viewerRef.current.camera) {
      viewerRef.current.camera.lookAt(
        Cesium.Cartesian3.fromDegrees(coordinates[0], coordinates[1], floor),
        new Cesium.HeadingPitchRange(
          Cesium.Math.toRadians(0.0),
          Cesium.Math.toRadians(-100 + (viewerRef.current.camera.positionCartographic.height / 1000) * 4),
          height
        )
      );

    } else {
      console.error('Cesium viewer is not initialized');
    }
  };

  const preLoad = (coordinates) => {
    if (viewerRef.current && viewerRef.current.camera) {
      viewerRef.current.camera.flyTo({
        destination: Cesium.Cartesian3.fromDegrees(coordinates[0], coordinates[1], 1000.0),
        duration: 0,
        complete: function() {
            console.log('Tiles preloaded');
        }
      });
    } else {
      console.error('Cesium viewer is not initialized');
    }
  };

  const handleUpcomingDeploymentsFacility = (facility) => {
    handleFacilitySelect(facility);
    setIsShippingVisible(false);
    setHBActive(false);
    setShowFacilityInfo(true);
    setShowFacilityFinder(false);
    setIsFacilityFinderOpen(true);
    setKBActive(false);
  }

  const zoomToCurrentFacility = () => {
    handleMapStyleChange(defaultFacilityMapStyle)
    setZoomScale(17);
    setZoomTo(selectedFacilityCoordinates);  
  }

  const handleOnTransitChange = (newTransitStates) => {
    setActiveTransits(newTransitStates);
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/login');
    } catch (error) {
      console.error('Error logging out:', error);
    }

  }

  const handleLogoClick = () => {
    resetApp();
  }

  const handleShowShippingAltRoutes = () => {
    setShowShippingAltRoutes(true);
  };

  window.receiveMessageFromUE = function(message) {
    console.log('Received from Unreal: ' + message);
  };

  const handleUnrealLevelClick = async () => {
  
    if (window.ue && window.ue.myobject && typeof window.ue.myobject.processjsmessage === 'function') {
        const result = await window.ue.myobject.processjsmessage("load3D");   
        console.log(result);
    }
  };

  const toggleFacilitiesClick = () => {
    setShipmentsRoutesReset(true);
    setShowShipmentsTracker(false);
    setIsShippingVisible(false);
    setHBActive(false);
    setKBActive(false);

    if (!showFacilityFinder && !showFacilityInfo) {
      setShowFacilityFinder(true);
      setIsFacilityFinderOpen(true);
      setFBActive(true);
    } else {
      if (showFacilityInfo && !isFacilityFinderOpen) {
        setShowFacilityFinder(true);
      } else if (!showFacilityInfo && isFacilityFinderOpen) {
        setIsFacilityFinderOpen(false);
        
        toggleFB();
      } else if (showFacilityInfo && isFacilityFinderOpen) {
        setShowFacilityFinder(true);
      } else {
        setShowFacilityFinder(true);
        setIsFacilityFinderOpen(true);
        setFBActive(true);
      }   
    }

  };

  const toggleShippingClick = () => {
    setShipmentsRoutesReset(true);
    setShowShipmentsTracker(false);
    setIsShippingVisible(!isShippingVisible);
    toggleHB();

    setShowFacilityInfo(false);
    setShowFacilityFinder(false);
    setShowDeploymentsTable(false);
    setIsFacilityFinderOpen(false);
    setFBActive(false);

    if (showLanduseMap) {
      setShowLanduseMap(false);
      zoomToCurrentFacility();
    }
    
  };

  const toggleKPIsClick = () => {
    
    setShowKPIsWindow(!showKPIsWindow);
    toggleKB();

    setShowFacilityInfo(false);
    setShowFacilityFinder(false);
    setShowDeploymentsTable(false);
    setIsFacilityFinderOpen(false);
    setFBActive(false);
  
    if (showLanduseMap) {
      setShowLanduseMap(false);
      zoomToCurrentFacility();
    }
   
  };

  const closeShippingPanel = () => {
    setIsShippingVisible(false);
    setHBActive(false);
  };

  const closeKPIPanel = () => {
    setShowLanduseMap(false);
    setShowKPIsWindow(false);
    setKBActive(false);
  };

  const handleExitClick = async () => {
    if (window.ue && window.ue.myobject && typeof window.ue.myobject.processjsmessage === 'function') {
        const result = await window.ue.myobject.processjsmessage("exit");
        console.log(result);
    }
  };

  const handleMenuClick = async () => {
    if (window.ue && window.ue.myobject && typeof window.ue.myobject.processjsmessage === 'function') {
        const result = await window.ue.myobject.processjsmessage("menu");
        console.log(result);
    }
  };

  function resetApp() {
      setMode("default");
      enablePanning();
      handleMapStyleChange(defaultSettingsMapStyle);
      setShowFacilityInfo(false);
      setShowFacilityFinder(false);
      setShowDeploymentsTable(false);
      setShowLanduseMap(false);
      setIsFacilityFinderOpen(false);
      setFBActive(false);
      setIsShippingVisible(false);
      setHBActive(false);
      setIsChatbotOpen(false);
      setCBActive(false);
      setShowKPIsWindow(false);
      setKBActive(false);
      resetTransitStates();

      hideShipmentsRoutes();
      setShipmentsRoutesReset(true);
      setShowShipmentsTracker(false);

      setZoomScale(2);
      setZoomTo([240,-5]);
  }

  window.receiveMessageFromUE = function(message) {
    console.log('Received from UE: ' + message);

    if (message === 'reset') {
      resetApp();
    }
  };

  const enablePanning = useCallback(() => {
    if (map) {
      map.setUserInteraction({
        dragPanInteraction: true
      });
    }
  }, [map]);
  
  useEffect(() => {
    return () => {
      enablePanning();
    };
  }, [enablePanning]);
  

  const handleDisruptionAlert = () => {
    setShippingAltRoutes(false);
    hideMapLayers();
    resetApp();
    setShowShipmentsTracker(true);
  }

  const showDCInfo = (facility) => {
    setMode('datacenters');
    setShowFacilityInfo(true);
    setShowFacilityFinder(false);
    handleFacilitySelect(facility);
    setIsFacilityFinderOpen(true);
  }

  useEffect(() => {
    if (showLanduseMap) {
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
      }, 100);
    }
  }, [showLanduseMap]);

  return (
    <ThemeProvider theme={theme}>
    <LoadingOverlay
            active={isLoading || isLoadingCesium}
            spinner
            text={isLoadingCesium ? "Loading Cesium Map..." : "Loading Azure Map..."}
            styles={{
                overlay: (base) => ({
                    ...base,
                    background: 'rgba(0, 0, 0, 0.8)', 
                }),
                content: (base) => ({
                    ...base,
                    color: '#fff', // Text color
                }),
            }}
        >
    <Box sx={{ display: 'flex', flexDirection: 'column', backgroundColor: '#1D1B20', minWidth: '1024px', 
        minHeight: '768px',
        width: '100%',
        height: '100vh',
        overflow: 'auto'}}>
      <AppBar position="static" color="default" elevation={0} 
      sx={{
        height: '74px',
        backgroundColor: '#1D1B20',
      }}>
        <Toolbar
          sx={{
            height: '100%', 
            display: 'flex',
            alignItems: 'center', 
            justifyContent: 'space-between', 
          }}
        >
          <Box onClick={handleLogoClick} sx={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>

          <Box sx={{ flexGrow: 0, display: 'flex', alignItems: 'center', mr: 1}}>
            <img src="./images/msn-logo-transparent.png" alt="Logo" style={{ height: '25px' }} />
          </Box>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ flexGrow: 0, display: { xs: 'none', sm: 'block' }, color: 'lightgray', fontSize: '19px', marginRight: '10px' }}
          >
            Microsoft SCN
          </Typography>
          </Box>
          <Box sx={{ 
            flexGrow: 1, 
            display: 'flex', 
            alignItems: 'center', 
            backgroundColor: '#141314',
            borderRadius: 10, 
            pl: 2,
            mr: 2,
            maxWidth: '420px',
            position: 'relative',
            border: '1px solid white', 
        }}>
            <InputBase
                id="search-input"
                placeholder="Search for Data Centers, Hubs and Suppliers"
                value={searchInput}
                onChange={handleSearchChange}
                sx={{ 
                    color: '#6A6A6A', 
                    width: '97%',
                    height: '40px',
                    backgroundColor: '#141314', 
                    borderRadius: '4px', 
                    padding: '8px 40px 8px 8px',
                    '& .MuiInputBase-input': {
                        padding: '8px 0',
                        '&::placeholder': {
                            color: 'lightblue', 
                        },
                    },
                }}
            />
            <SearchIcon sx={{ 
                color: '#BFECFF', 
                position: 'absolute', 
                right: '10px', 
                top: '50%', 
                transform: 'translateY(-50%)' 
            }} />
            {suggestions.length > 0 && (
                <List sx={{ 
                  position: 'absolute', 
                  zIndex: 1101, 
                  maxHeight: '200px', 
                  overflowY: 'auto', 
                  width: '92%', 
                  top: 'calc(100% + 2px)',
                  padding: 0, 
                  color: 'white',
                  border: '1px solid #ccc', 
                  borderRadius: '4px',
                  backgroundColor: '#141314'
              }}>
                  {suggestions.map((facility, index) => (
                      <ListItem 
                          button 
                          key={index} 
                          onClick={() => handleSuggestionClick(facility)}
                          sx={{
                              cursor: 'pointer',
                              '&:hover': {
                                  backgroundColor: '#BFECFF',
                                  color: '#141314', 
                              },
                          }}
                      >
                          {facility.properties.FACILITY}
                      </ListItem>
                  ))}
              </List>
            )}
        </Box>

          
          <FacilitiesButton onClick={toggleFacilitiesClick} isSelected={fbActive} />
          <ShippingButton onClick={toggleShippingClick} isSelected={hbActive} />
          <KPIButton onClick={toggleKPIsClick} isSelected={kbActive} />
          <ChatButton onClick={toggleChatbot} isSelected={cbActive}/>
          
          <Box sx={{ flexGrow: 1 }} />

          
          <Box sx={{ marginRight: '35px' }}>
            <IconButton
              size="large"
              aria-label="show 2 new notifications"
              color="inherit"
              onClick={toggleNotifications}
            >
              <Badge badgeContent={notifBageVisible ? 2 : 0} color="error">
                <NotificationIcon  sx={{ fontSize: '30px', color: '#BFECFF' }}/>
              </Badge>
            </IconButton>
          </Box>
          
          <UserProfilePanel handleSignOut={handleLogout} datacentersData={filteredDatacenters} handleExitClick={handleExitClick} handleMenuClick={handleMenuClick}/>
        </Toolbar>
      </AppBar>
      {selectedDC && (
            <Box
              sx={{
                  position: 'absolute',
                  top: 450,
                  right: 0,
                  transform: 'translate(-50%, -100%)',
                  width: '300px', 
                  height: '350px',
                  zIndex: 1100
              }}
            >
              <DCInfoWindow facilityData={selectedDC} onClose={handleDCClose} />
            </Box>
          )}
      <Box sx={{ display: 'flex', flexGrow: 1, overflow: 'hidden', background: '#1D1B20', color: '#2B2930' }}>
          {isShippingVisible && (
            <Draggable handle=".handle" position={position} onDrag={handleDrag}>
            <Box
            sx={{
              position: 'absolute',
              left: 15, 
              top: 15,
              width: '750px',
              overflowY: 'hidden',
              height: `${shippingWindowHeight}px`, 
              backgroundColor: '#1D1B20',
              zIndex: 1010,
              boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
              display: 'flex',
              borderRadius: '30px',
              flexDirection: 'column'
            }}
          >
            <Box
              className="handle" 
              sx={{
                backgroundColor: '#2D2B30', 
                padding: '10px',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                borderBottom: '1px solid #3D3B40', 
                cursor: 'move' 
              }}
            >
              <Typography variant="h6" color="white" sx={{paddingLeft: '10px'}}>
                Upcoming Deployments
              </Typography>
              <IconButton onClick={closeShippingPanel} size="small" sx={{ color: 'white' }}>
                <CloseIcon />
              </IconButton>
            </Box>
            
            <Box sx={{ flexGrow: 1, overflow: 'hidden', padding: '15px 0', marginBottom: '0'}}>
              <DeploymentsWindow 
                onFacilitySelect={handleUpcomingDeploymentsFacility}
                onHeightChange={handleShippingHeightChange}
              />
            </Box>
          </Box>
          </Draggable>
          )}
          {showKPIsWindow && (
            <Draggable handle=".handle" position={KPIPosition} onDrag={handleKPIDrag}>
            <Box
            sx={{
              position: 'absolute',
              left: 775, 
              top: 15,
              overflowY: 'hidden',
              width: '750px',
              height: '430px', 
              backgroundColor: '#1D1B20',
              zIndex: 1010,
              boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
              display: 'flex',
              borderRadius: '30px',
              flexDirection: 'column'
            }}
          >
            <Box
              className="handle" 
              sx={{
                backgroundColor: '#2D2B30', 
                padding: '10px',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                borderBottom: '1px solid #3D3B40', 
                cursor: 'move' 
              }}
            >
              <Typography variant="h6" color="white" sx={{paddingLeft: '10px'}}>
                Global KPIs
              </Typography>
              <IconButton onClick={closeKPIPanel} size="small" sx={{ color: 'white' }}>
                <CloseIcon />
              </IconButton>
            </Box>
            
            <Box sx={{ flexGrow: 1, overflow: 'hidden', padding: '15px 0', marginBottom: '0'}}>
              <GlobalKPIWindow />
            </Box>
          </Box>
          </Draggable>
          )}
          {isFacilityFinderOpen &&  (
          <Box
          sx={{
            position: 'absolute',
            left: 0,
            top: 74,
            width: '300px',
            height: 'calc(100% - 74px)',
            backgroundColor: '#1D1B20',
            zIndex: 1000,
            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
          }}
        >
          
          <FacilityComponent
              showFacilityFinder={showFacilityFinder}
              datacentersData={filteredDatacenters}
              onFacilitySelect={handleFacilitySelect}
              selectedFacility={currentFacilityData}
              showFacilityInfo={showFacilityInfo}
              setShowSeperateDeploymentsTable={showShippingAndDeliveries}
              setShowLanduseMap={setShowLanduseMap}
              handleFacilityInfoClose={handleFacilityInfoClose}
              onClose={closeFacilityComponent}
              handleUnrealLevelClick={handleUnrealLevelClick}
              mode={mode}
            />
        </Box>
        )}
        
        <Box sx={{ 
          flexGrow: 1,
          backgroundColor: 'black', 
          padding: 0, 
          textAlign: 'center',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
           
           
            <div ref={mapRef} id="myMap" style={{ display: !showLanduseMap && !showCesiumMap ? 'block' : 'none', width: '100%', height: '100%' }}>
            {map && (
                <>
                  {showShipmentsTracker  ? (
                    <MapShipmentsRoutes
                      map={map}
                      mapStyle={mapStyle}
                      zoomScale={zoomScale}
                      zoomTo={zoomTo}
                      showAlternativeRoutes={shippingAltRoutes}
                      onDCSelect={handleDCSelect}
                      showOriginalRoutes={shippingOriginalRoutes}
                      onLayersCreated={handleLayersCreated}
                    />
                  ) : showShippingRoutes ? (
                    <MapShippingRoutes
                      map={map}
                      mapStyle={mapStyle}
                      zoomScale={zoomScale}
                      zoomTo={zoomTo}
                      showShippingRoutes={showShipmentsRoutes}
                      setShippingLayers={setShippingLayers}
                      showShippingAltRoutes={showShippingAltRoutes}
                    />
                  ) : (
                    <AzureMapDirect
                      map={map}
                      mapStyle={activeMapStyle}
                      activeTransits={activeTransits}
                      showDCInfo={showDCInfo}
                      zoomScale={zoomScale}
                      zoomTo={zoomTo}
                      datacentersData={dcData}
                      seaportsData={spData}
                      airportsData={apData}
                    />
                  )}
                  {/*
                    <MapPathMaker map={map}/>
                  */}
                  
                </>
              )}
            </div>
           
            <div ref={cesiumContainerRef} 
              style={{ 
                display: showCesiumMap ? 'block' : 'none', 
                width: '100%', 
                height: '100%'
            }}/>

            <div style={{ display: showLanduseMap ? 'block' : 'none', width: '100%', height: '100%' }}>
              <OSMLanduseMap 
                center={[currentFacilityData.FacilityCoordinates.lat, currentFacilityData.FacilityCoordinates.long]} 
                zoom={12}
                overlays={overlays}
                onOverlayChange={handleOverlayChange}
              >
                <OSMLanduseData key={selectedYear} year={selectedYear} />
              </OSMLanduseMap>
            </div>
            <MapStyleController
              activeStyle={activeMapStyle}
              onStyleChange={handleMapStyleChange}
              onZoomIn={handleZoomIn}
              onZoomOut={handleZoomOut}
              transitStates={activeTransits}
              onTransitToggle={handleTransitToggle}
              onTransitChange={handleOnTransitChange}
            />
          <ChatbotComponent isOpen={isChatbotOpen} onClose={toggleChatbot} />
          <NotificationsComponent 
            isOpen={nActive} 
            onClose={toggleNotifications} 
            onDeploymentAlert={focusOnTheDeploymentAlert}
            onDisruptionAlert={handleDisruptionAlert}
            firstOpen={isFirstNotifOpen} 
          />
          
      </Box>
    </Box>
      
      {showLanduseMap ? (
          <Box 
            sx={{ 
              position: 'absolute', 
              width: showFacilityFinder ? 'calc(100% - 725px)': 'calc(100% - 425px)', 
              height: '330px',
              marginLeft: "auto", 
              backgroundColor: '#333333',
              zIndex: 1001,
              right: 0,
              bottom: 0,
              overflow: 'hidden'
            }}
          >
            
            <LanduseTimeline
             onClose={handleCloseLandImpact}
                onYearSelect={handleYearSelect}
                selectedYear={selectedYear}
                onOverlayChange={handleOverlayChange}
              />
          </Box>
        ) : (
          showDeploymentsTable && currentFacilityData && (
            <Box 
              sx={{ 
                position: 'absolute', 
                width: showFacilityFinder ? 'calc(100% - 725px)': 'calc(100% - 425px)', 
                height: '400px',
                marginLeft: "auto", 
                backgroundColor: '#333333',
                zIndex: 1001,
                right: 0,
                bottom: 0,
                overflow: 'hidden'
              }}
            >
              <DatacenterDeployments 
                facilityData={currentFacilityData}
                onClose={handleDatacenterDeploymentsClose}
                showShipmentsRoutes={showShipmentsRoutes}
                hideShipmentsRoutes={hideShipmentsRoutesAndShowFacility}
                startOpenTab={deploymentsActiveTab}
                onShowAltRoutes={handleShowShippingAltRoutes}
              />
            </Box>
          )
        )}

        {showShipmentsTracker && (
            <Box 
              sx={{ 
                position: 'absolute', 
                width: '100%', 
                height: '400px',
                marginLeft: "auto", 
                backgroundColor: '#333333',
                zIndex: 1001,
                right: 0,
                bottom: 0,
                overflow: 'hidden'
              }}
            >
              <ShipmentsTracker onClose={onShipmentsTrackerClose} showOriginalRoutes={setShippingOriginalRoutes} showShippingAltRoutes={setShippingAltRoutes} handleLayersVisibility={handleLayersVisibility}/>
            </Box>
          )}
    </Box>
    </LoadingOverlay>
    </ThemeProvider>
  );
};
