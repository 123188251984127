import React, { useState } from 'react';
import { Box, Paper, Typography, TextField, IconButton, Card, CardContent } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ImageIcon from '@mui/icons-material/Image';

const ChatbotComponent = ({ isOpen, onClose }) => {
  const [message, setMessage] = useState('');

  const suggestions = [
    { 
      title: "Track & Optimize your Shipping Schedule",
      description: "Improve efficiency and reduce costs"
    },
    { 
      title: "Forecast with weather and event simulation",
      description: "Prepare for various scenarios"
    },
  ];

  const handleSend = () => {
    console.log('Sending message:', message);
    setMessage('');
  };

  if (!isOpen) return null;

  return (
    <Paper
      elevation={3}
      sx={{
        position: 'fixed',
        bottom: 20,
        right: 20,
        width: 400,
        height: 700,
        display: 'flex',
        flexDirection: 'column',
        bgcolor: 'rgba(30, 30, 30, 0.9)',
        color: 'white',
        borderRadius: 2,
      }}
    >
      <Box sx={{ p: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h6">Copilot</Typography>
        <IconButton onClick={onClose} sx={{ color: 'white' }}>
          <CloseIcon />
        </IconButton>
      </Box>
      
      <Box sx={{ 
        flexGrow: 1, 
        overflowY: 'auto', 
        p: 2, 
        display: 'flex', 
        flexDirection: 'column', 
        justifyContent: 'center',
        gap: 3,
      }}>
        {suggestions.map((suggestion, index) => (
          <Card key={index} sx={{ 
            bgcolor: 'rgba(50, 50, 50, 0.8)', 
            color: 'white', 
            width: 'calc(100% - 50px)',
            mx: 'auto',
            borderRadius: 2,
          }}>
            <Box sx={{
              height: 100,
              bgcolor: 'rgba(70, 70, 70, 0.8)',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '8px 8px 0 0',
            }}>
              <ImageIcon sx={{ fontSize: 48, color: '#4fc3f7' }} />
            </Box>
            
            <CardContent sx={{ pt: 2, pb: 2 }}> 
                <Typography variant="h7" sx={{ fontWeight: 'bold', mb: 1 }}>
                    {suggestion.title}
                </Typography>
                <Typography variant="body2">
                    {suggestion.description}
                </Typography>
            </CardContent>
          </Card>
        ))}
      </Box>
      
      <Box sx={{ p: 3, mt: 4, position: 'relative' }}>
        <TextField
            fullWidth
            variant="outlined"
            placeholder="Ask me anything..."
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            sx={{ 
            '& .MuiOutlinedInput-root': { 
                color: 'white',
                borderRadius: '25px',
                pr: '48px', 
                '& fieldset': { borderColor: '#444' },
                '&:hover fieldset': { borderColor: '#666' },
                '&.Mui-focused fieldset': { borderColor: '#4fc3f7' },
            }
            }}
        />
        <IconButton 
            onClick={handleSend} 
            sx={{ 
            position: 'absolute',
            right: '32px', 
            top: '50%',
            transform: 'translateY(-50%)',
            color: 'white', 
            bgcolor: '#4fc3f7', 
            '&:hover': { bgcolor: '#3da8db' },
            width: '36px', 
            height: '36px', 
            }}
        >
            <ArrowUpwardIcon />
        </IconButton>
        </Box>
    </Paper>
  );
};

export default ChatbotComponent;