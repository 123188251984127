import * as atlas from 'azure-maps-control';

const PieChartMarker = (options) => {
    const {
        position,
        values,
        colors,
        radius,
        fillColor,
        strokeWidth,
        strokeColor,
        innerRadius,
        text,
        includeBackground = false
    } = options;

    console.log(' ----------------- radius' + radius);
    const total = values.reduce((sum, value) => sum + value, 0);
    const slices = values.map((value, i) => {
        if (value === 0) return ''; // Skip zero values
        const startAngle = values.slice(0, i).reduce((sum, v) => sum + v, 0) / total * Math.PI * 2;
        const endAngle = (values.slice(0, i + 1).reduce((sum, v) => sum + v, 0) / total) * Math.PI * 2;
        
        const x1 = radius + radius * Math.sin(startAngle);
        const y1 = radius - radius * Math.cos(startAngle);
        const x2 = radius + radius * Math.sin(endAngle);
        const y2 = radius - radius * Math.cos(endAngle);
        
        const largeArcFlag = endAngle - startAngle > Math.PI ? 1 : 0;
        
        return `<path d="M${radius},${radius} L${x1},${y1} A${radius},${radius} 0 ${largeArcFlag} 1 ${x2},${y2} Z" fill="${colors[i]}" stroke="${strokeColor}" stroke-width="${strokeWidth}" />`;
    }).join('');
    const createSVG = () => {
        const total = values.reduce((a, b) => a + b, 0);
        let startAngle = 0;
        const slices = values.map((value, i) => {
            if (value === 0) return '';
            const angle = (value / total) * Math.PI * 2;
            const largeArcFlag = angle > Math.PI ? 1 : 0;
            const x1 = Math.cos(startAngle) * radius;
            const y1 = Math.sin(startAngle) * radius;
            const x2 = Math.cos(startAngle + angle) * radius;
            const y2 = Math.sin(startAngle + angle) * radius;
            const path = `M 0 0 L ${x1} ${y1} A ${radius} ${radius} 0 ${largeArcFlag} 1 ${x2} ${y2} Z`;
            startAngle += angle;
            return `<path d="${path}" fill="${colors[i]}" stroke="${strokeColor}" stroke-width="${strokeWidth}" />`;
        }).join('');

        const svg = `
        <svg width="${radius * 2}" height="${radius * 2}" viewBox="${-radius} ${-radius} ${radius * 2} ${radius * 2}" xmlns="http://www.w3.org/2000/svg">
            ${includeBackground ? `<circle r="${radius}" fill="white" stroke="black" stroke-width="${strokeWidth}" />` : ''}
            ${slices}
            <circle r="${innerRadius}" fill="${fillColor}" stroke="${strokeColor}" stroke-width="${strokeWidth}" />
            <text x="0" y="5" font-size="14" text-anchor="middle" fill="black">${text}</text>
        </svg>
    `;

        return `data:image/svg+xml;base64,${btoa(svg)}`;
    };

    const svgDataUrl = createSVG();

    const svg = `
    <svg width="${radius * 2}" height="${radius * 2}" viewBox="0 0 ${radius * 2} ${radius * 2}" xmlns="http://www.w3.org/2000/svg">
        ${options.includeBackground ? `<circle cx="${radius}" cy="${radius}" r="${radius}" fill="${fillColor}" stroke="${strokeColor}" stroke-width="${strokeWidth}" />` : ''}
        ${slices}
        <circle cx="${radius}" cy="${radius}" r="${innerRadius}" fill="${fillColor}" stroke="${strokeColor}" stroke-width="${strokeWidth}" />
        ${text ? `<text x="${radius}" y="${radius + 5}" font-size="14" text-anchor="middle" fill="black">${text}</text>` : ''}
    </svg>
`;

    return `<div style="width:${radius * 2}px; height:${radius * 2}px;">${svg}</div>`;
};

export default PieChartMarker;