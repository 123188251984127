import React, { useState, useRef, useEffect } from 'react';
import { Box, Card, CardContent, Typography, Grid, IconButton, Collapse } from '@mui/material';
import { styled } from '@mui/material/styles';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

const createData = (datacenter, supplier, origin, requested, scheduled, status, priority, facilityObject) => {
  return { datacenter, supplier, origin, requested, scheduled, status, priority, facilityObject };
};

const facilityObjects = {
  'Quincy MWH06': {
    "type": "Feature",
    "geometry": {
      "type": "Point",
      "coordinates": [-119.8828, 47.23342]
    },
    "properties": {
      "FACILITY": "Quincy MWH06",
      "FACILITYSHORTCODE": "MWH06",
      "DCOPSREGION": "US West",
      "CITY": "Quincy",
      "SHIPPINGSTATE": "WA",
      "SHIPPINGCITY": "Quincy",
      "SHIPPINGADDRESS": "1515 Port Industrial Way",
      "SHIPPINGZIPCODE": "98848-5002",
      "COUNTRY": "United States",
      "EntityType": "maybeLate"
    }
  },
  'Boston BOS32': {
    "type": "Feature",
    "geometry": {
      "type": "Point",
      "coordinates": [-71.219038, 42.301218]
    },
    "properties": {
      "FACILITY": "Boston BOS32",
      "FACILITYSHORTCODE": "BOS32",
      "DCOPSREGION": "US East",
      "CITY": "NEEDHAM HEIGHTS",
      "SHIPPINGSTATE": "MA",
      "SHIPPINGCITY": "Needham Heights",
      "SHIPPINGADDRESS": "128 1ST AVE",
      "SHIPPINGZIPCODE": "02494-2805",
      "COUNTRY": "United States",
      "EntityType": "defLate"
    }
  }
};

const rows = [
  createData('Quincy MWH06', 'Micron', 'USA', '05/10/2024', '08/12/2024', 'Delayed', '1', facilityObjects['Quincy MWH06']),
  createData('Boston BOS32', 'Samsung', 'S. Korea', '06/11/2024', '08/13/2024', 'On Time', '2', facilityObjects['Boston BOS32']),
];

const getStatusColor = (status) => {
  switch (status) {
    case 'Delayed':
      return '#FF4136';
    case 'Risk':
      return '#FFDC00';
    case 'On Time':
    case 'Ready':
      return '#2ECC40';
    default:
      return 'transparent';
  }
};

const createSubTableData = (item, qty, status) => {
  return { item, qty, status };
};

const getSubTableRows = (rowCount, mainTableStatus) => {
  const items = ['Network Switch', 'Motherboard', 'Rack', 'Power Supply'];
  const rows = [];
  const usedItems = new Set();

  for (let i = 0; i < rowCount; i++) {
    let status;
    if (mainTableStatus === 'On Time') {
      status = 'Ready';
    } else if (mainTableStatus === 'Delayed') {
      status = i === 0 ? 'Delayed' : (Math.random() < 0.7 ? 'Ready' : 'Risk');
    } else {
      status = Math.random() < 0.7 ? 'Ready' : 'Risk';
    }

    let item;
    do {
      item = items[Math.floor(Math.random() * items.length)];
    } while (usedItems.has(item));
    usedItems.add(item);

    rows.push(createSubTableData(
      item,
      Math.floor(Math.random() * 100) + 1,
      status
    ));
  }

  return rows;
};

const StyledCard = styled(Card)(({ theme }) => ({
  width: '350px',
  minHeight: '180px',
  backgroundColor: 'rgba(243,243,238,0.9)',
  color: 'black',
  borderRadius: theme.spacing(2),
  margin: '0 8px',
  flexShrink: 0,
  position: 'relative',
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  paddingBottom: '0px'
}));

const ClickableBox = styled(Box)(({ theme }) => ({
  border: '1px dashed rgba(255, 255, 255, 0.5)',
  borderRadius: theme.spacing(1),
  padding: theme.spacing(1),
  marginBottom: theme.spacing(2),
  cursor: 'pointer',
  display: 'flex',
  position: 'relative',
  overflow: 'hidden',
  '&:hover': {
    backgroundColor: 'rgba(210, 234, 253, 0.1)',
  },
}));

const StatusBar = styled(Box)(({ status }) => ({
  position: 'absolute',
  left: 0,
  top: 0,
  bottom: 0,
  width: '8px',
  backgroundColor: getStatusColor(status),
}));

const SliderContainer = styled(Box)({
  display: 'flex',
  overflow: 'hidden',
  width: 'calc(100% - 60px)',
  position: 'relative',
  margin: '0 30px'
});

const ScrollButton = styled(IconButton)(({ theme, direction }) => ({
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  [direction]: 0,
  zIndex: 2,
  backgroundColor: 'rgba(255, 255, 255, 0.1)',
  color: 'white',
  width: '24px',
  height: '24px',
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
  },
}));



const DeploymentsWindow = ({ onFacilitySelect, onHeightChange }) => {
  const containerRef = useRef(null);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(true);
  const [expandedCards, setExpandedCards] = useState({});

 

  const handleScroll = (direction) => {
    const container = containerRef.current;
    if (container) {
      const cardWidth = 350 + 16;
      const scrollAmount = direction === 'right' ? cardWidth : -cardWidth;
      const newPosition = Math.max(0, Math.min(scrollPosition + scrollAmount, container.scrollWidth - container.clientWidth));
      container.scrollTo({ left: newPosition, behavior: 'smooth' });
      setScrollPosition(newPosition);
    }
  };

  const toggleCardExpansion = (index) => {
    setExpandedCards(prev => {
      const newExpandedCards = {...prev, [index]: !prev[index]};
      const expandedCount = Object.values(newExpandedCards).filter(Boolean).length;
      onHeightChange(expandedCount > 0 ? 510 : 390); 
      return newExpandedCards;
    });
  };

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      setShowLeftArrow(scrollPosition > 0);
      setShowRightArrow(scrollPosition < container.scrollWidth - container.clientWidth);
    }
  }, [scrollPosition]);

  return (
    <Box sx={{ position: 'relative', width: '100%', mt: 3, height: 'auto' }}>
      {showLeftArrow && (
        <ScrollButton direction="left" onClick={() => handleScroll('left')} sx={{left: '8px'}}>
          <ArrowBackIosIcon fontSize="small" sx={{color: '#BFECFF', backgroundColor: '#333'}}/>
        </ScrollButton>
      )}
      <SliderContainer ref={containerRef}>
        {rows.map((row, index) => (
          <StyledCard key={index}>
            <CardContent sx={{ p: 2 }}>
              <Box sx={{mb: 1}}>
                <Typography variant="h6">{index === 0 ? "US West" : "US East"}</Typography>
              </Box>
              <ClickableBox 
                onClick={() => onFacilitySelect(row.facilityObject)}
                sx={{
                  '&:hover': {
                    '& .deployment-text': {
                      color: 'blue',
                    },
                  },
                }}
              >
                <StatusBar status={row.status} />
                <Box sx={{ display: 'flex', alignItems: 'flex-start', ml: 2 }}>
                  <Box sx={{ textAlign: 'center', mr: 2 }}>
                    <Typography variant="caption" sx={{ color: 'grey.500', fontSize: '0.7rem' }}>AUG</Typography>
                    <Typography variant="h5" sx={{ lineHeight: 1 }}>{index === 0 ? '9' : '16'}</Typography>
                  </Box>
                  <Box>
                    <Typography variant="h6" className="deployment-text" sx={{ fontSize: '1.1rem', transition: 'color 0.3s' }}>
                      {index === 0 ? 'MWH23' : 'BOS32'} Deployment {index === 0 ? '64' : '16'}
                    </Typography>
                    <Typography variant="body2" sx={{ color: 'grey.600' }}>
                      {index === 0 ? 'Quincy, WA' : 'Boston, MA'}
                    </Typography>
                  </Box>
                </Box>
              </ClickableBox>
              <Typography variant="body2">Supplier: {row.supplier}</Typography>
              <Typography variant="body2">Origin: {row.origin}</Typography>
              <Typography variant="body2">Scheduled: {row.scheduled}</Typography>
              <Typography 
                variant="body2" 
                sx={{ cursor: 'pointer', color: 'blue', mt: 1 }}
                onClick={() => toggleCardExpansion(index)}
              >
                {expandedCards[index] ? 'See Less' : 'See All'}
              </Typography>
              <Collapse in={expandedCards[index]}>
                <Grid container spacing={1} sx={{ mt: 2 }}>
                  <Grid item xs={5}><Typography variant="body2">Item</Typography></Grid>
                  <Grid item xs={3}><Typography variant="body2">QTY</Typography></Grid>
                  <Grid item xs={4}><Typography variant="body2">Status</Typography></Grid>
                  {getSubTableRows(3, row.status).map((subRow, subIndex) => (
                    <React.Fragment key={subIndex}>
                      <Grid item xs={5}><Typography variant="body2">{subRow.item}</Typography></Grid>
                      <Grid item xs={3}><Typography variant="body2">{subRow.qty}</Typography></Grid>
                      <Grid item xs={4}>
                        <Box display="flex" alignItems="center">
                          <Box
                            sx={{
                              width: 8,
                              height: 8,
                              borderRadius: '50%',
                              backgroundColor: getStatusColor(subRow.status),
                              marginRight: 1,
                            }}
                          />
                          <Typography variant="body2">{subRow.status}</Typography>
                        </Box>
                      </Grid>
                    </React.Fragment>
                  ))}
                </Grid>
              </Collapse>
            </CardContent>
          </StyledCard>
        ))}
      </SliderContainer>
      {showRightArrow && (
        <ScrollButton direction="right" onClick={() => handleScroll('right')} sx={{right: '8px'}}>
          <ArrowForwardIosIcon fontSize="small" sx={{color: '#BFECFF', backgroundColor: '#333'}}/>
        </ScrollButton>
      )}
    </Box>
  );
};

export default DeploymentsWindow;