import React, { useRef, useState, useEffect } from 'react';
import { Box, Typography, Card, CardContent, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

const StyledCard = styled(Card)(({ theme }) => ({
  backgroundColor: '#2a2a2a',
  color: 'white',
  borderRadius: theme.spacing(0.5),
  width: '280px',
  height: '60px',
  marginRight: theme.spacing(2),
  position: 'relative',
  overflow: 'hidden',
  boxShadow: 'none',
  flexShrink: 0,
}));

const StatusBar = styled(Box)(({ status }) => ({
  position: 'absolute',
  left: 0,
  top: 0,
  bottom: 0,
  width: '4px',
  backgroundColor: status,
}));

const CardContentWrapper = styled(Box)({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  paddingLeft: '12px',
});

const KPITitle = styled(Typography)({
  fontWeight: 'bold',
  fontSize: '0.8rem',
  marginBottom: '2px',
});

const KPIValue = styled('span')({
  fontWeight: 'bold',
  textDecoration: 'underline',
});

const DataBox = styled(Box)(({ color }) => ({
  width: 'calc(15% - 5px)',
  height: 100,
  backgroundColor: color,
  color: 'black',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  padding: 8,
  borderRadius: 8,
  margin: 8,
}));

const SliderContainer = styled(Box)({
  display: 'flex',
  overflowX: 'hidden',
  width: 'calc(100% - 70px)',
  position: 'relative',
  margin: '0 30px',
});

const ScrollButton = styled(IconButton)(({ theme, direction }) => ({
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  [direction]: 0,
  zIndex: 2,
  backgroundColor: 'rgba(255, 255, 255, 0.1)',
  color: 'white',
  width: '24px',
  height: '24px',
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
  },
}));

const getStatusColor = (status) => {
  switch (status) {
    case 'green':
      return '#2ECC40';
    case 'yellow':
      return '#FFDC00';
    case 'red':
      return '#FF4136';
    default:
      return '#FFFFFF';
  }
};

const GlobalKPIWindow = () => {
  const kpiData = [
    { title: '% of Aging Inventory in Hubs', target: '<15%', actual: '23%', status: 'red' },
    { title: 'On Time Delivery', target: '>95%', actual: '97%', status: 'green' },
    { title: 'Inventory Accuracy', target: '>99%', actual: '98.5%', status: 'yellow' },
    { title: 'Supplier Quality', target: '<0.5%', actual: '0.3%', status: 'green' },
    { title: 'Inventory Turnover', target: '>12', actual: '10', status: 'yellow' },
    { title: 'Order Fill Rate', target: '>98%', actual: '96%', status: 'yellow' }
  ];

  const additionalData = [
    { title: 'Datacenters', subtitle: 'Operational', value: '328', color: '#FF9800' },
    { title: 'Datacenters', subtitle: 'In Deployment', value: '32', color: '#00BCD4' },
    { title: 'Datacenters', subtitle: 'Under Construction', value: '150', color: '#9C27B0' },
    { title: 'Warehouses', subtitle: 'Operational', value: '61', color: '#8BC34A' },
    { title: 'In Transit', subtitle: 'Ships', value: '74', color: '#4CAF50' },
    { title: 'In Transit', subtitle: 'Planes', value: '43', color: '#2196F3' },
    { title: 'In Transit', subtitle: 'Trains', value: '54', color: '#F44336' }
  ];

  const containerRef = useRef(null);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(true);

  const handleScroll = (direction) => {
    const container = containerRef.current;
    if (container) {
      const scrollAmount = direction === 'right' ? 280 : -280;
      const newPosition = Math.max(0, Math.min(scrollPosition + scrollAmount, container.scrollWidth - container.clientWidth));
      container.scrollTo({ left: newPosition, behavior: 'smooth' });
      setScrollPosition(newPosition);
    }
  };

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      setShowLeftArrow(scrollPosition > 0);
      setShowRightArrow(scrollPosition < container.scrollWidth - container.clientWidth);
    }
  }, [scrollPosition]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', p: 1 }}>
      <Box sx={{ position: 'relative', width: '100%', mb: 2 }}>
        {showLeftArrow && (
          <ScrollButton direction="left" onClick={() => handleScroll('left')} sx={{left: '8px'}}>
            <ArrowBackIosIcon fontSize="small" sx={{color: '#BFECFF', backgroundColor: '#333'}}/>
          </ScrollButton>
        )}
        <SliderContainer ref={containerRef}>
          {kpiData.map((kpi, index) => (
            <StyledCard key={index}>
              <StatusBar status={getStatusColor(kpi.status)} />
              <CardContent sx={{ py: 0, px: 2, height: '100%' }}>
                <CardContentWrapper>
                  <KPITitle>{kpi.title}</KPITitle>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <Typography variant="body2" sx={{ fontSize: '0.7rem' }}>
                      Target: <KPIValue>{kpi.target}</KPIValue>
                    </Typography>
                    <Typography variant="body2" sx={{ fontSize: '0.7rem' }}>
                      Actual: <KPIValue>{kpi.actual}</KPIValue>
                    </Typography>
                  </Box>
                </CardContentWrapper>
              </CardContent>
            </StyledCard>
          ))}
        </SliderContainer>
        {showRightArrow && (
          <ScrollButton direction="right" onClick={() => handleScroll('right')} sx={{right: '8px'}}>
            <ArrowForwardIosIcon fontSize="small" sx={{color: '#BFECFF', backgroundColor: '#333'}}/>
          </ScrollButton>
        )}
      </Box>
      <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', width: '100%' }}>
        {additionalData.map((item, index) => (
          <DataBox key={index} color={item.color}>
            <Box sx={{ textAlign: 'center' }}>
              <Typography variant="caption" sx={{ fontWeight: 'bold', fontSize: '0.6rem' }}>
                {item.title}
              </Typography>
              <Typography variant="body2" sx={{ fontSize: '0.7rem' }}>{item.subtitle}</Typography>
            </Box>
            <Typography 
              variant="h4" 
              sx={{ 
                textAlign: 'center', 
                flex: 1, 
                display: 'flex', 
                alignItems: 'center', 
                justifyContent: 'center' 
              }}
            >
              {item.value}
            </Typography>
          </DataBox>
        ))}
      </Box>
    </Box>
  );
};

export default GlobalKPIWindow;