import React, { useEffect, useRef, useState, useCallback } from 'react';
import * as atlas from 'azure-maps-control';
import 'azure-maps-control/dist/atlas.min.css';
import PieChartMarker from './PieChartMarker';


const AzureMapDirect = ({ map, mapStyle, activeTransits, showDCInfo, zoomTo, zoomScale = 17, datacentersData, seaportsData, airportsData}) => {
    const [lastClickedMarker, setLastClickedMarker] = useState(null);
    const [dcLayer, setDcLayer] = useState(null);
    const [dcSource, setDcSource] = useState(null);
    const [dcClusterLayers, setDcClusterLayers] = useState(null);
    const [spLayer, setSpLayer] = useState(null);
    const [spSource, setSpSource] = useState(null);
    const [spClusterLayers, setSpClusterLayers] = useState(null);
    const [apLayer, setApLayer] = useState(null);
    const [apSource, setApSource] = useState(null);
    const [apClusterLayers, setApClusterLayers] = useState(null);
    const initializedRef = useRef(false);
    const datacenterLayersRef = useRef([]);
    const seaportLayersRef = useRef([]);
    const airportLayersRef = useRef([]);

    const entityTypes = ['early', 'onTime', 'maybeLate', 'defLate']; 
    const entityTypesColors = ['blue', 'green', 'yellow', 'red']; 

    const updateClusterMarkers__ = useCallback(() => {
      if (!map || !dcSource) return;
  
      // Clear existing markers
      map.markers.clear();
  
      const camera = map.getCamera();
      const bounds = camera.bounds;
  
      const shapes = dcSource.getShapes(bounds);
      shapes.forEach(shape => {
          const properties = shape.getProperties();
          console.log(' /////////////    PROPS CLUSTER : ' + properties.point_count);
          console.log(' /////////////    PROPS COORDINATES: ' + shape.getCoordinates());
          console.log(' /////////////    PROPS EARLY: ' + properties.early);
          if (properties.cluster) {
              const position = shape.getCoordinates();
              const radius = getPieChartRadiusFromPointCount(properties.point_count);
              const values = [
                  properties.early || 0,
                  properties.onTime || 0,
                  properties.maybeLate || 0,
                  properties.defLate || 0
              ];
  
              const pieChartMarker = new atlas.HtmlMarker({
                  htmlContent: PieChartMarker({
                      values: values,
                      colors: entityTypesColors,
                      radius: radius,
                      fillColor: 'transparent',
                      strokeWidth: 1,
                      strokeColor: 'black',
                      innerRadius: radius * 0.6,
                      text: properties.point_count.toString(),
                      includeBackground: true
                  }),
                  position: position,
                  pixelOffset: [0, 0],
                  anchor: 'center'
              });
  
              map.markers.add(pieChartMarker);
          }

          const pieChartMarker = new atlas.HtmlMarker({
            htmlContent: PieChartMarker({
                values: [10,20,40,30],
                colors: entityTypesColors,
                radius: 35,
                fillColor: 'white',
                strokeWidth: 1,
                strokeColor: 'black',
                innerRadius: 35 * 0.6,
                text: '125',
                includeBackground: true
            }),
            position: shape.getCoordinates(),
            pixelOffset: [0, 0],
            anchor: 'center'
        });

        map.markers.add(pieChartMarker);
      });
  }, [map, dcSource, entityTypesColors]);
  
  
  const initializeDataSource = useCallback(async (map, data, seaportsData, airportsData) => {
        if (!map || !data || !seaportsData || !airportsData) return;

        const dcDataSource = new atlas.source.DataSource(null, {
            cluster: true,
            clusterRadius: 150,
            clusterMaxZoom: 13,
            clusterProperties: {
              'early': ['+', ['case', ['==', ['get', 'EntityType'], 'early'], 1, 0]],
              'onTime': ['+', ['case', ['==', ['get', 'EntityType'], 'onTime'], 1, 0]],
              'maybeLate': ['+', ['case', ['==', ['get', 'EntityType'], 'maybeLate'], 1, 0]],
              'defLate': ['+', ['case', ['==', ['get', 'EntityType'], 'defLate'], 1, 0]]
          }
        });
        
        map.sources.add(dcDataSource);
        setDcSource(dcDataSource)
        
        const enhancedData = data.features.map(feature => {
            return new atlas.Shape(new atlas.data.Point(feature.geometry.coordinates), null, {
                ...feature.properties,
                facility: JSON.stringify(feature)
            });
        });
        
        dcDataSource.add(enhancedData);
        
        const dcClusterLayer = new atlas.layer.BubbleLayer(dcDataSource, 'dcClusterLayer', {
            radius: [
                'step',
                ['get', 'point_count'],
                30,
                300,
                60,
                1050,
                90
            ],
            color: 'white',
            strokeColor: 'black',
            strokeWidth: 1,
            allowOverlap: true,
            filter: ['has', 'point_count']
        });
        
        const dcClusterTextLayer = new atlas.layer.SymbolLayer(dcDataSource, 'dcClusterTextLayer', {
            iconOptions: { image: 'none' },
            textOptions: {
                textField: ['get', 'point_count_abbreviated'],
                offset: [0, 0.4]
            },
            filter: ['has', 'point_count']
        });
        
        const dcSymbolLayer = new atlas.layer.SymbolLayer(dcDataSource, 'dcSymbolLayer', {
            iconOptions: { image: 'marker-blue' },
            textOptions: {
                textField: ['get', 'FACILITY'],
                offset: [0, 1.5],
                color: 'black',
                haloColor: 'white',
                haloWidth: 1,
                placement: 'point',
                allowOverlap: true,
                ignorePlacement: false
            },
            filter: ['!', ['has', 'point_count']]
        });
        
        map.layers.add([dcClusterLayer, dcClusterTextLayer, dcSymbolLayer]);
        setDcClusterLayers([dcClusterLayer, dcClusterTextLayer]);
        setDcLayer(dcSymbolLayer);

        datacenterLayersRef.current = [dcClusterLayer, dcClusterTextLayer, dcSymbolLayer];
        
        const spDataSource = new atlas.source.DataSource(null, {
            cluster: true,
            clusterRadius: 150,
            clusterMaxZoom: 5,
        });
        
        map.sources.add(spDataSource);
        setSpSource(spDataSource);

        const seaportFeatures = seaportsData.map(port => new atlas.data.Feature(
            new atlas.data.Point([port.lon, port.lat]),
            { name: port.tags.name, type: 'seaport', ...port.tags }
        ));
        
        spDataSource.add(seaportFeatures);
        
        const spClusterLayer = new atlas.layer.BubbleLayer(spDataSource, 'spClusterLayer', {
            radius: [
                'step',
                ['get', 'point_count'],
                20,
                400,
                30,
                500,
                20
            ],
            color: 'yellow',
            strokeColor: 'black',
            strokeWidth: 1,
            filter: ['has', 'point_count']
        });
        
        const spClusterTextLayer = new atlas.layer.SymbolLayer(spDataSource, 'spClusterTextLayer', {
            iconOptions: { image: 'none' },
            textOptions: {
                textField: ['get', 'point_count_abbreviated'],
                offset: [0, 0.4],
                color: 'black'
            },
            filter: ['has', 'point_count']
        });
        
        const spSymbolLayer = new atlas.layer.SymbolLayer(spDataSource, 'spSymbolLayer', {
            iconOptions: { image: 'marker-yellow', size: 0.5 },
            textOptions: {
                textField: ['get', 'name'],
                offset: [0, 1.5],
                color: 'yellow',
                haloColor: 'black',
                haloWidth: 1,
                placement: 'point',
                allowOverlap: true,
                ignorePlacement: false
            },
            filter: ['!', ['has', 'point_count']]
        });
        map.layers.add([spClusterLayer, spClusterTextLayer, spSymbolLayer]);
        setSpClusterLayers([spClusterLayer, spClusterTextLayer]);
        setSpLayer(spSymbolLayer);
        
        seaportLayersRef.current = [spClusterLayer, spClusterTextLayer, spSymbolLayer];
        
        const apDataSource = new atlas.source.DataSource(null, {
            cluster: true,
            clusterRadius: 400,
            clusterMaxZoom: 8
        });
        
        map.sources.add(apDataSource);
        setApSource(apDataSource);

        const apFeatures = airportsData.map(airport => new atlas.data.Feature(
            new atlas.data.Point([airport.lon, airport.lat]),
            { name: airport.tags.name, type: 'airport', ...airport.tags }
        ));
        
        apDataSource.add(apFeatures);
        
        const apClusterLayer = new atlas.layer.BubbleLayer(apDataSource, 'apClusterLayer', {
            radius: [
                'step',
                ['get', 'point_count'],
                20,
                200,
                30,
                400,
                20
            ],
            color: 'darkblue',
            strokeColor: 'blue',
            strokeWidth: 1,
            filter: ['has', 'point_count']
        });
        
        const apClusterTextLayer = new atlas.layer.SymbolLayer(apDataSource, 'apClusterTextLayer', {
            iconOptions: { image: 'none' },
            textOptions: {
                textField: ['get', 'point_count_abbreviated'],
                offset: [0, 0.4],
                color: 'white'
            },
            filter: ['has', 'point_count']
        });
        
        const apSymbolLayer = new atlas.layer.SymbolLayer(apDataSource, 'apSymbolLayer', {
            iconOptions: { image: 'marker-red', size: 0.5 },
            textOptions: {
                textField: ['get', 'name'],
                offset: [0, 1.5],
                color: 'orange',
                haloColor: 'black',
                haloWidth: 1,
                placement: 'point',
                allowOverlap: true,
                ignorePlacement: false
            },
            filter: ['!', ['has', 'point_count']]
        });
        map.layers.add([apClusterLayer, apClusterTextLayer, apSymbolLayer]);
        setApClusterLayers([apClusterLayer, apClusterTextLayer]);
        setApLayer(apSymbolLayer);
        airportLayersRef.current = [apClusterLayer, apClusterTextLayer, apSymbolLayer];

        initializedRef.current = true;
    }, []);

  
    useEffect(() => {
        if (!map || !datacentersData || !seaportsData || !airportsData || initializedRef.current) return;
        initializeDataSource(map, datacentersData, seaportsData, airportsData);
    }, [map, datacentersData, seaportsData, airportsData, initializeDataSource]);

    useEffect(() => {
        if (map) {
            map.setStyle({ style: mapStyle });
        }
    }, [map, mapStyle]);

    useEffect(() => {
        if (!map || !dcClusterLayers || !dcLayer || !spLayer || !spClusterLayers || !apLayer || !apClusterLayers || !activeTransits) return;
        const setLayerVisibility = (layer, visible) => {
            layer.setOptions({ visible: visible });
        };
        if (activeTransits.datacenters) {
            dcClusterLayers.forEach(layer => setLayerVisibility(layer, true));
            setLayerVisibility(dcLayer, true);
        } else {
            dcClusterLayers.forEach(layer => setLayerVisibility(layer, false));
            setLayerVisibility(dcLayer, false);
        }
        if (activeTransits.seaports) {
            spClusterLayers.forEach(layer => setLayerVisibility(layer, true));
            setLayerVisibility(spLayer, true);
        } else {
            spClusterLayers.forEach(layer => setLayerVisibility(layer, false));
            setLayerVisibility(spLayer, false);
        }
        if (activeTransits.airports) {
            apClusterLayers.forEach(layer => setLayerVisibility(layer, true));
            setLayerVisibility(apLayer, true);
        } else {
            apClusterLayers.forEach(layer => setLayerVisibility(layer, false));
            setLayerVisibility(apLayer, false);
        }
    }, [map, activeTransits, dcClusterLayers, dcLayer, spLayer, spClusterLayers, apLayer, apClusterLayers]);
  
    const handleClusterClick = useCallback((e) => {
        if (!dcSource || !map) return;
        const properties = e.shapes[0].properties;
        const position = e.position;
        if (properties.cluster) {
            map.getCanvasContainer().style.cursor = 'pointer';
            dcSource.getClusterExpansionZoom(properties.cluster_id).then((zoom) => {
                map.setCamera({
                    center: position,
                    zoom: zoom + 2,
                    type: 'ease',
                    duration: 100,
                    easingFunction: 'easeOutCubic'
                });
            });
            {/*
            const pieChartMarker = new atlas.HtmlMarker({
              htmlContent: PieChartMarker({
                  values: [10,20,40,30],
                  colors: entityTypesColors,
                  radius: 35,
                  fillColor: 'white',
                  strokeWidth: 1,
                  strokeColor: 'black',
                  innerRadius: 35 * 0.6,
                  text: properties.point_count,
                  includeBackground: true
              }),
              position: position,
              pixelOffset: [0, 0],
              anchor: 'center'
          });
  
          map.markers.add(pieChartMarker);
          */}
        }
    }, [dcSource, map]);

    const handleSeaportClusterClick = useCallback((e) => {
        if (!spSource || !map) return;
        const properties = e.shapes[0].properties;
        const position = e.position;
        if (properties.cluster) {
            map.getCanvasContainer().style.cursor = 'grab';
            spSource.getClusterExpansionZoom(properties.cluster_id).then((zoom) => {
                map.setCamera({
                    center: position,
                    zoom: zoom + 2,
                    type: 'ease',
                    duration: 100,
                    easingFunction: 'easeOutCubic'
                });
            });
        }
    }, [spSource, map]);

    const handleApClusterClick = useCallback((e) => {
        if (!apSource || !map) return;
        const properties = e.shapes[0].properties;
        const position = e.position;
        if (properties.cluster) {
            map.getCanvasContainer().style.cursor = 'grab';
            apSource.getClusterExpansionZoom(properties.cluster_id).then((zoom) => {
                map.setCamera({
                    center: position,
                    zoom: zoom + 2,
                    type: 'ease',
                    duration: 100,
                    easingFunction: 'easeOutCubic'
                });
            });
        }
    }, [apSource, map]);

    const handlePinClick = useCallback((e) => {
        if (!map) return;
        const properties = e.shapes[0].getProperties();
        const position = e.shapes[0].getCoordinates();
        if (lastClickedMarker) {
            map.markers.remove(lastClickedMarker);
        }
        const color = properties.EntityType === 'early' ? 'green' : 'red';
        const newMarker = new atlas.HtmlMarker({ position: position, color: color });
        map.markers.add(newMarker);
        setLastClickedMarker(newMarker);
        const facility = properties.facility ? JSON.parse(properties.facility) : properties;
        showDCInfo(facility);
    }, [map, lastClickedMarker, showDCInfo]);

    useEffect(() => {
      if (map && dcLayer && dcClusterLayers && spLayer && spClusterLayers && apLayer && apClusterLayers) {
          map.events.add('click', dcLayer, handlePinClick);
          dcClusterLayers.forEach(layer => {
              if (layer.getOptions().filter && layer.getOptions().filter[0] === 'has' && layer.getOptions().filter[1] === 'point_count') {
                  map.events.add('click', layer, handleClusterClick);
              }
          });
          spClusterLayers.forEach(layer => {
              if (layer.getOptions().filter && layer.getOptions().filter[0] === 'has' && layer.getOptions().filter[1] === 'point_count') {
                  map.events.add('click', layer, handleSeaportClusterClick);
              }
          });
          apClusterLayers.forEach(layer => {
              if (layer.getOptions().filter && layer.getOptions().filter[0] === 'has' && layer.getOptions().filter[1] === 'point_count') {
                  map.events.add('click', layer, handleApClusterClick);
              }
          });
          return () => {
              map.events.remove('click', dcLayer, handlePinClick);
              dcClusterLayers.forEach(layer => {
                  if (layer.getOptions().filter && layer.getOptions().filter[0] === 'has' && layer.getOptions().filter[1] === 'point_count') {
                      map.events.remove('click', layer, handleClusterClick);
                  }
              });
              spClusterLayers.forEach(layer => {
                  if (layer.getOptions().filter && layer.getOptions().filter[0] === 'has' && layer.getOptions().filter[1] === 'point_count') {
                      map.events.remove('click', layer, handleSeaportClusterClick);
                  }
              });
              apClusterLayers.forEach(layer => {
                  if (layer.getOptions().filter && layer.getOptions().filter[0] === 'has' && layer.getOptions().filter[1] === 'point_count') {
                      map.events.remove('click', layer, handleApClusterClick);
                  }
              });
          };
      }
  }, [map, handlePinClick, handleClusterClick, handleSeaportClusterClick, handleApClusterClick, dcLayer, dcClusterLayers, spLayer, spClusterLayers, apLayer, apClusterLayers]);
  
  useEffect(() => {
      if (!zoomTo) return;
  
      if (map && zoomTo) {
          map.setCamera({
              center: zoomTo,
              zoom: zoomScale,  
              type: 'ease',
              duration: 100,
              easingFunction: 'easeOutCubic' 
          });
      }
  }, [map, zoomTo, zoomScale]);

  const getPieChartRadiusFromPointCount = (pointCount) => {
    if (pointCount >= 1000) return 50;
    if (pointCount >= 100) return 40;
    if (pointCount >= 10) return 30;
    return 20;
  };


  const getColorByEntityType = (entityType) => {
      switch (entityType) {
          case 'early': return 'green';
          case 'onTime': return 'blue';
          case 'maybeLate': return 'yellow';
          case 'defLate': return 'red';
          default: return 'gray';
      }
  };

  
  if (!datacentersData) {
      return <div>Loading...</div>;
  }
  
  return null;
  
  };
  
  export default AzureMapDirect;