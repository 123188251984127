import { useEffect, useState, useCallback, useRef } from 'react';
import * as atlas from 'azure-maps-control';

const MapShippingRoutes = ({ map, mapStyle, zoomTo, zoomScale = 2, showShippingRoutes, setShippingLayers, showShippingAltRoutes = false }) => {
    const [deploymentsData, setDeploymentsData] = useState(null);
    const layersRef = useRef([]);
    const markersRef = useRef([]);

    useEffect(() => {
        const loadData = async () => {
            try {
                const [shippingRoutes] = await Promise.all([
                    import('../data/deployments.json')
                ]);
                setDeploymentsData(shippingRoutes.default);
            } catch (error) {
                console.error('Error loading data:', error);
            }
        };
        loadData();
    }, []);

    useEffect(() => {
        if (!map || !mapStyle || !zoomTo) return;

        map.setStyle({ style: mapStyle });

        if (map && zoomTo) {
            map.setCamera({
                center: zoomTo,
                zoom: zoomScale,
                type: 'ease',
                duration: 100,
                easingFunction: 'easeOutCubic'
            });
        }
    }, [map, mapStyle, zoomTo, zoomScale]);

    useEffect(() => {
        if (!map) return;
    
        const addImageIfNotExists = (imageName, imageUrl) => {
            if (!map.imageSprite.hasImage(imageName)) {
                return map.imageSprite.add(imageName, imageUrl);
            }
            return Promise.resolve();
        };
    
        Promise.all([
            addImageIfNotExists('customRedCircle', '../images/icon_circle_red.png'),
            addImageIfNotExists('customYellowCircle', '../images/icon_circle_yellow.png'),
            addImageIfNotExists('customBlueCircle', '../images/icon_circle_blue.png'),
            addImageIfNotExists('customGreenCircle', '../images/icon_circle_green.png'),
            addImageIfNotExists('customArrow', '../images/icon_arrow_blue.png')
        ]).then(() => {
            console.log('map images for routes loaded!');
        }).catch((error) => {
            console.error('Error adding images:', error);
        });
    }, [map]);

    const showDeploymentTransit = useCallback((data, pointPercent) => {
        if (!map) return;

        const routesLineSource = new atlas.source.DataSource();
        const routesDottedSource = new atlas.source.DataSource();
        const altDottedSource = new atlas.source.DataSource();
        const pointsSource = new atlas.source.DataSource();
        const altPointsSource = new atlas.source.DataSource();

        const lineLayer = new atlas.layer.LineLayer(routesLineSource, 'lineLayer', {
            color: 'blue',
            width: 4
        });

        const altLineLayer = new atlas.layer.LineLayer(altDottedSource, 'altlineLayer', {
            strokeColor: 'green',
            width: 4,
            strokeDashArray: [2, 4],
            visible: showShippingAltRoutes
        });
        const dottedLayer = new atlas.layer.LineLayer(routesDottedSource, 'dottedLayer', {
            color: 'blue',
            width: 4,
            strokeDashArray: [2, 4],
        });
        const circleLayer = new atlas.layer.SymbolLayer(pointsSource, 'circleLayer', {
            iconOptions: {
                image: 'customBlueCircle',
                size: 0.1,
                anchor: 'center',
                allowOverlap: true  
            },
            filter: ['==', ['get', 'type'], 'start']
        });

        const circleRedLayer = new atlas.layer.SymbolLayer(pointsSource, 'circleRedLayer', {
            iconOptions: {
                image: 'customRedCircle',
                size: 0.1,
                anchor: 'center',
                allowOverlap: true
            },
            filter: ['==', ['get', 'type'], 'end']
        });

        const circleYellowLayer = new atlas.layer.SymbolLayer(altPointsSource, 'circleYellowLayer', {
            iconOptions: {
                image: 'customYellowCircle',
                size: 0.1,
                anchor: 'center',
                allowOverlap: true
            },
            filter: ['==', ['get', 'type'], 'alt'],
            visible: showShippingAltRoutes
        });

        const arrowLayer = new atlas.layer.SymbolLayer(pointsSource, 'arrowLayer', {
            iconOptions: {
                image: 'customArrow',
                allowOverlap: true,
                anchor: 'center',
                size: 0.15,
                rotation: ['get', 'rotation']
            },
            filter: ['==', ['get', 'type'], 'arrow']
        });
        
        map.sources.add(routesLineSource);
        map.sources.add(routesDottedSource);
        map.sources.add(altDottedSource);
        map.sources.add(pointsSource);
        map.sources.add(altPointsSource);

        map.layers.add(altLineLayer);
        map.layers.add(lineLayer);
        map.layers.add(dottedLayer);
        map.layers.add(circleLayer);
        map.layers.add(circleRedLayer);
        map.layers.add(circleYellowLayer);
        map.layers.add(arrowLayer);

        layersRef.current = [altLineLayer, lineLayer, dottedLayer, circleLayer, circleRedLayer, circleYellowLayer, arrowLayer];
        markersRef.current = [];

        var altRouteAvailable = false;
        var destHubAvailable = false;
        var destFinalAvailable = false;
        var altEndCoords = [];
        var destHubCoords = [];
        var destFinalCoords = [];
        var imgType = '<img src="../images/icon_boat_grey_1.png" style="width:40px;height:40px;margin:3px;" />';

        data.datacenter.deployments.forEach((deployment) => {
            deployment.shipments.forEach((shipment) => {
                const startCoords = [shipment.source.geolocation.long, shipment.source.geolocation.lat];
                const endCoords = [shipment.target.geolocation.long, shipment.target.geolocation.lat];
                altRouteAvailable = false;
                destHubAvailable = false;
                destFinalAvailable = false;

                if (shipment.target_alt !== undefined) {
                    altRouteAvailable = true;
                    altEndCoords = [shipment.target_alt.geolocation.long, shipment.target_alt.geolocation.lat];
                }

                if (shipment.destination_hub !== undefined) {
                    destHubAvailable = true;
                    destHubCoords = [shipment.destination_hub.geolocation.long, shipment.destination_hub.geolocation.lat];
                }
                
                if (shipment.destination_final !== undefined) {
                    destFinalAvailable = true;
                    destFinalCoords = [shipment.destination_final.geolocation.long, shipment.destination_final.geolocation.lat];
                }

                const path = [startCoords, pointAtPercentage(startCoords, endCoords, pointPercent), endCoords];

                const newLineString = new atlas.data.LineString([path[0], path[1]]);
                const newDottedString = new atlas.data.LineString([path[1], path[2]]);

                if (destHubAvailable) {
                    const dottedLine = new atlas.data.LineString([path[2], destHubCoords]);
                    
                    altDottedSource.add(dottedLine);
                    const targetMarker = new atlas.HtmlMarker({
                        position: destHubCoords,
                        color: 'blue'
                    });
    
                    map.markers.add(targetMarker);
                    markersRef.current.push(targetMarker);
                }

                if (destFinalAvailable && destHubAvailable) {
                    const dottedLine = new atlas.data.LineString([destHubCoords, destFinalCoords]);
                    
                    altDottedSource.add(dottedLine);
                    const targetMarker = new atlas.HtmlMarker({
                        position: destFinalCoords,
                        color: 'blue'
                    });
    
                    map.markers.add(targetMarker);
                    markersRef.current.push(targetMarker);
                }

                if (altRouteAvailable) {
                    const altDottedString = new atlas.data.LineString([path[1], altEndCoords]);
                    
                    altDottedSource.add(altDottedString);
                    altPointsSource.add(new atlas.data.Feature(new atlas.data.Point(altEndCoords), { type: 'alt' }));
                }

                if (altRouteAvailable & destHubAvailable) {
                    const dottedLine = new atlas.data.LineString([altEndCoords, destHubCoords]);
                    
                    altDottedSource.add(dottedLine);
                }
                
                routesLineSource.add(newLineString);
                routesDottedSource.add(newDottedString);

                pointsSource.add(new atlas.data.Feature(new atlas.data.Point(startCoords), { type: 'start' }));
                pointsSource.add(new atlas.data.Feature(new atlas.data.Point(endCoords), { type: 'end' }));

                const bearing = atlas.math.getHeading(path[1], endCoords);
                const midpoint = pointAtPercentage(startCoords, endCoords, 50);
                pointsSource.add(new atlas.data.Feature(new atlas.data.Point(midpoint), { type: 'arrow', rotation: bearing}));
                
                if (shipment.target.type === 'airport') {
                    imgType = '<img src="../images/icon_plane_grey_1.png" style="width:40px;height:40px;margin:3px;" />';
                }

                if (shipment.target.type === 'train') {
                    imgType = '<img src="../images/icon_train_grey_1.png" style="width:40px;height:40px;margin:3px;" />';
                }

                const marker = new atlas.HtmlMarker({
                    position: path[1],
                    htmlContent: imgType
                });

                map.markers.add(marker);
                markersRef.current.push(marker);

                const startMarker = new atlas.HtmlMarker({
                    position: startCoords,
                    color: 'green'
                });
                
                const endMarker = new atlas.HtmlMarker({
                    position: endCoords,
                    color: 'red'
                });

                markersRef.current.push(startMarker, endMarker);
            });
        });

        setShippingLayers([...layersRef.current, ...markersRef.current]);
    }, [map, setShippingLayers, showShippingAltRoutes]);

    const pointAtPercentage = useCallback((startCoords, endCoords, percentage) => {
        percentage = Math.max(0, Math.min(100, percentage)) / 100;
        
        const lon = startCoords[0] + (endCoords[0] - startCoords[0]) * percentage;
        const lat = startCoords[1] + (endCoords[1] - startCoords[1]) * percentage;
        
        return [lon, lat];
    }, []);

    useEffect(() => {
        if (map && deploymentsData) {
            showDeploymentTransit(deploymentsData, 50);
        }
    }, [map, deploymentsData, showDeploymentTransit]);

    useEffect(() => {
        if (layersRef.current.length > 0) {
            const altLineLayer = layersRef.current.find(layer => layer.getId() === 'altlineLayer');
            const circleYellowLayer = layersRef.current.find(layer => layer.getId() === 'circleYellowLayer');
            if (altLineLayer) {
                altLineLayer.setOptions({ visible: showShippingAltRoutes });
            }
            if (circleYellowLayer) {
                circleYellowLayer.setOptions({ visible: showShippingAltRoutes });
            }
        }
    }, [showShippingAltRoutes]);

    return null;
};

export default MapShippingRoutes;